import {
  getSession,
  clearSession,
  createSessionId,
  getLastViewedScreen,
  setSession,
} from "../services/manager";
import { ReactComponent as SpinnerIcon } from "../assets/icons/spinner.svg";
import { startSession } from "../services/api";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BackgroundImage from "../assets/background.webp";
import Button from "../components/Button";
import Dialog from "../components/Dialog";
import ENFlag from "../assets/flags/en.svg";
import Logo from "../assets/logo.svg";
import NLFlag from "../assets/flags/nl.svg";
import RespondentTextField from "../components/RespondentTextField";
import PincodeNumberField from "../components/PincodeNumberField";
import type { AxiosError } from "axios";
import useValidation from "../hooks/useValidation";

export default function StartSessionScreen() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [respondentNumber, setRespondentNumber] = useState("");
  const [pincode, setPincode] = useState("");
  const [isAborted, setAborted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const validation = useValidation(pincode, "numeric|size:5");
  const loadingDelay = useRef<number>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const validPincodes = [
    30611, 53031, 29889, 95650, 59455, 29761, 78943, 65015, 29575, 57523, 12345,
    67890, 13579, 24680, 98765, 97531, 80246, 91573, 72893, 64102, 86420, 75319,
    46827, 35746, 14703, 25968, 38124, 96357, 68420, 57213, 19283, 45678, 32109,
    23456, 98701, 87654, 54320, 67891, 78912, 89123, 91234, 23451, 34512, 45123,
    51234, 12354, 23415, 34561, 45621, 56123, 98734, 87623, 65432, 32189, 78965,
    45693, 14725, 85246, 36987, 65421,
  ];

  useEffect(() => {
    if (getSession()) {
      setAborted(true);
    }
    console.log("env: " + process.env.REACT_APP_ENVIRONMENT);
    i18n.changeLanguage("en");
  }, [i18n]);

  async function handleStartSession() {
    if (respondentNumber === "") {
      setErrorMessage(t("enter_your_name"));
      return;
    }

    setErrorMessage(validation.error);
    if (validation.failed) return;

    if (!validPincodes.find((p) => p === parseInt(pincode))) {
      setErrorMessage(t("pincode_not_valid"));
      return;
    }

    // Delay show of loading icon
    clearTimeout(loadingDelay.current);
    loadingDelay.current = window.setTimeout(() => setLoading(true), 1000);

    try {
      const sessionId = createSessionId();

      // Send request to the api, make sure we can connect.
      if (!process.env.REACT_APP_SKIP_START_SESSION) {
        await startSession(sessionId, respondentNumber, pincode);
      }

      setSession(sessionId);
      navigate("/session/welcome");
    } catch (e) {
      const error = e as AxiosError;
      if (error.isAxiosError) {
        setErrorMessage(error.message);
      }
    } finally {
      setLoading(false);
    }
  }

  async function handleStartNewSession() {
    await clearSession();
    setAborted(false);
  }

  async function handleContinueSession() {
    const viewData = await getLastViewedScreen();
    if (viewData) navigate(viewData.path, { replace: true });
  }

  const loadingIcon = isLoading ? (
    <SpinnerIcon className="animate-spin h-5 absolute left-0" />
  ) : undefined;

  function getVersion() {
    if (process.env.REACT_APP_ENVIRONMENT === "DEV")
      return (
        <div className="text-center">v{process.env.REACT_APP_VERSION}</div>
      );
  }

  return (
    <div
      className="min-h-screen pt-28 p-12 flex flex-col gap-6 justify-between items-center bg-no-repeat bg-cover bg-center"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      {isAborted && <div className="absolute inset-0 bg-skyblue/50"></div>}

      <div className="relative text-center">
        <img
          src={Logo}
          alt="Moovd logo"
          className="inline-block mb-2 h-10"
          width="153"
          height="40"
        />
        <h1 className="text-4xl font-bold">Digital EMDR</h1>
      </div>

      <div className="relative flex flex-col flex-1 justify-center gap-6 max-w-xs">
        {isAborted && (
          <>
            <h2 className="text-2xl font-bold text-center">
              {t("your_session_has_been_aborted_but_not_yet_completed")}
            </h2>
            <Button
              label={t("continue_session")}
              variant="quaternary"
              size="large"
              shadow
              onClick={() => handleContinueSession()}
              className="w-full flex-none"
            />
            <button
              className="underline text-center"
              onClick={() => handleStartNewSession()}
            >
              {t("or_start_a_new_session")}
            </button>
          </>
        )}

        {!isAborted && (
          <>
            <div className="relative text-center pin">
              <h1 className="text-xl font-bold">
                {t(
                  "enter_the_name_of_your_therapist_and_the_pincode_for_your_organisation_below"
                )}
              </h1>
            </div>
            <div className="w-73">
              <RespondentTextField
                value={respondentNumber}
                onChange={setRespondentNumber}
              />
            </div>
            <div className="w-73">
              <PincodeNumberField value={pincode} onChange={setPincode} />
            </div>

            {errorMessage && (
              <Dialog
                actions={
                  <Button
                    onClick={() => setErrorMessage(null)}
                    label={t("close")}
                  />
                }
              >
                {t("Error: ")} {errorMessage}
              </Dialog>
            )}

            <Button
              label={t("start")}
              variant="quaternary"
              size="large"
              shadow
              onClick={handleStartSession}
              icon={loadingIcon}
            />
          </>
        )}

        <div className="relative text-center mt-6">
          <p className="text-lg mb-2">{t("choose_language")}</p>
          <img
            onClick={() => i18n.changeLanguage("nl")}
            src={NLFlag}
            alt="Dutch"
            width="32"
            height="32"
            className="h-8 inline-block mx-1 cursor-pointer"
          />
          <img
            onClick={() => i18n.changeLanguage("en")}
            src={ENFlag}
            alt="English"
            width="32"
            height="32"
            className="h-8 inline-block mx-1 cursor-pointer"
          />
        </div>
        {getVersion()}
      </div>
    </div>
  );
}
