import { getLatestScreenData } from "../services/manager";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import type { StoredValue as PreviousStoredValue2 } from "../screens/session/Step1_Screens/IntroductionScreen01";
import type { StoredValue as PreviousStoredValue9 } from "../screens/session/Step2_Screens/NegativeCoreScreen03";

export default function usePreviousNCValue(screen?: string): string {
  const [nc, setNc] = useState("(unknown)");
  const { t } = useTranslation();

  useEffect(() => {
    (async function () {
      const previousData2 = await getLatestScreenData("2");
      const previousData9 = await getLatestScreenData("9");

      // Return input data from screen 2.
      if (
        (previousData2?.answer && screen === "2") ||
        (previousData2?.answer && screen === "3")
      ) {
        return setNc(previousData2.answer as PreviousStoredValue2);
      }

      // Return input data from screen 9.
      if (
        (previousData9?.answer && screen === "10") ||
        (previousData9?.answer && screen === "11") ||
        (previousData9?.answer && screen === "13")
      ) {
        return setNc(previousData9.answer as PreviousStoredValue9);
      }
    })();
  }, [t, nc]);

  return nc;
}
